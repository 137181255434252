import { CommonInput, CommonSelect, ImageInput } from '../../../components/widgets';

import strings from '../../../localization';

const Profile = ({
  data, categories, register, watch,
}) => {
  const [logo] = [watch('logo')];
  return (
    <>
      <form>
        <h4>Temel Bilgiler</h4>
        <section className="grid grid-3">
          <CommonInput
            label="Mekan Adı"
            placeholder="eg: Sunset Events"
            register={register('name', { required: true })}
            defaultValue={data?.name}
          />
          <CommonInput
            label="Mekan Telefonu"
            placeholder="eg: +90 216 000 0000"
            register={register('phone', { required: true })}
            defaultValue={data?.phone}
          />
          <CommonSelect
            label="Mekan Kategorisi"
            placeholder="Lütfen bir kategori seçin"
            register={register('category')}
            defaultValue={data?.category}
            options={categories}
            domain="category"
          />
        </section>
        <h4 className="mb-0 mt-2">Mekan Logosu</h4>
        <ImageInput
          register={register('logo', { required: true })}
          defaultValue={data?.logo}
          watch={logo}
          placeholder="1024x1024 veya 1:1 oranda logo"
          label="Mekan Logosu"
        />
      </form>
      <form>
        <h4 className="mb-0 mt-2"> Sosyal Medya Hesapları</h4>
        <section className="grid grid-4">
          <CommonInput
            label={strings['commons-social-instagram']}
            register={register('socials.instagram')}
            placeholder="eg: instagram.com/username"
            defaultValue={data?.socials?.instagram}
            append={(<i className="fa fa-instagram mr" />)}
          />
          <CommonInput
            label={strings['commons-social-twitter']}
            register={register('socials.twitter')}
            defaultValue={data?.socials?.twitter}
            placeholder="eg: twitter.com/username"
            append={(<i className="fa fa-twitter mr" />)}
          />
          <CommonInput
            label={strings['commons-social-facebook']}
            register={register('socials.facebook')}
            defaultValue={data?.socials?.facebook}
            placeholder="eg: facebook.com/username"
            append={(<i className="fa fa-facebook mr" />)}
          />
          <CommonInput
            label={strings['commons-social-website']}
            register={register('socials.website')}
            defaultValue={data?.socials?.website}
            placeholder="eg: organizer.com"
            append={(<i className="fa fa-globe mr" />)}
          />
        </section>
        <h4 className="mb-0 mt-2">Açıklamalar</h4>
        <section className="grid grid-2">
          <CommonInput
            label="Türkçe Açıklama"
            register={register('desc.tr', { required: true, maxLength: 140 })}
            defaultValue={data?.desc?.tr}
            placeholder="Lütfen 140 karakteri geçmecek şekilde kısa bir açıklama yazınız."
            multiline
            multilineHeight={5}
          />
          <CommonInput
            label="İngilizce Açıklama"
            register={register('desc.en', { maxLength: 140 })}
            defaultValue={data?.desc?.en}
            placeholder="Please write a brief description of the venue in Englis and in max 140 characters."
            multiline
            multilineHeight={5}
          />
        </section>
      </form>
    </>
  );
};

export default Profile;
