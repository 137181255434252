import { getToken } from './storage';

// const api = 'http://localhost:3000/api'; // localhost
// const api = 'http://192.168.2.227:3000/api'; // local localhost

let api = 'https://api.esevents.app/api'; // production
if (window.location.host.includes('dev-')) { // development
  api = 'https://api.hibilet.com/api';
}

const headerBuilder = (header, form) => ({
  ...(form ? { 'Content-Type': 'application/json' } : null),
  ...(getToken() ? { authorization: getToken() } : null),
  ...{ header },
});

const handler = async (res) => {
  if (!res.ok) {
    throw (await res.json());
  } else { return res.json(); }
};

const params = (method, header, form) => ({
  headers: headerBuilder(header, form),
  body: JSON.stringify(form),
  method,
});

const get = (endpoint, header = null) => fetch(api + endpoint, params('GET', header)).then(handler);
const post = (endpoint, form = null, header = null) => fetch(api + endpoint, params('POST', header, form)).then(handler);
const put = (endpoint, form = null, header = null) => fetch(api + endpoint, params('PUT', header, form)).then(handler);
const del = (endpoint, form = null, header = null) => fetch(api + endpoint, params('DELETE', header, form)).then(handler);

export {
  get, post, del, put,
};
