/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { Link } from 'wouter';

const EventsTable = ({
  data, pages, index, pager,
}) => (
  <main className="main table-responsive">
    <h4 className="flex-grow-1 flex-grow-1 mb-3">{data?.length > 0 ? `Toplam ${pages} etkinlik var` : 'Hiç etkinlik yok'}</h4>
    <table className="table">
      <thead>
        <tr>
          <th scope="col" className="text-center">#</th>
          <th scope="col">Etkinlik Adı</th>
          <th scope="col">Organizatör</th>
          <th scope="col">Tarih Aralığı</th>
          <th scope="col">Fiyat Aralığı</th>
          <th scope="col">Satışlar</th>
          <th scope="col">Seanslar</th>
        </tr>
      </thead>
      <tbody>
        {data.map((entity, i) => (
          <tr key={entity._id}>
            <td><div>{index + i + 1}</div></td>
            <td>
              <div>
                <img className="mr-2 rounded" alt="logo" src={entity?.image} width={64} height={64} />
                <Link to={`/events/${entity?._id}`}>{entity?.name}</Link>
              </div>
            </td>
            <td><div>{entity?.owner?.name}</div></td>
            <td>
              <div>
                {(() => {
                  if (entity?.dates?.min) {
                    if (entity?.dates?.min !== entity?.dates?.max) {
                      return `${dayjs.utc(entity?.dates?.min).format('DD MMM YYYY')} - ${dayjs.utc(entity?.dates?.max).format('DD MMM YYYY')}`;
                    } return dayjs.utc(entity?.dates?.min).format('DD MMM YYYY');
                  } return 'Satış Bilgisi Yok';
                })()}
              </div>
            </td>
            <td>
              <div>
                {(() => {
                  if (entity?.tickets?.min) {
                    if (entity?.tickets?.min !== entity?.tickets?.max) {
                      return `${entity?.tickets?.min} ${entity?.currency} - ${entity?.tickets?.max} ${entity?.currency}`;
                    } return `${entity?.tickets?.min} ${entity?.currency}`;
                  } return 'Satış Bilgisi Yok';
                })()}
              </div>

            </td>
            <td><div>{`${entity?.sales || 0} Satış`}</div></td>
            <td><div>{`${entity?.sessions || 0} Satış`}</div></td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="float-right">{pager}</div>
  </main>
);

export default EventsTable;
