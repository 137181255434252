import AddressForm from '../../../components/forms/Address';

const Address = ({ data, register, watch }) => (
  <form>
    <h4>Mekan Adresi</h4>
    <AddressForm data={data} watch={watch} register={register} />
  </form>
);

export default Address;
