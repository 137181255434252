import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { CommonInput, CommonSelect, PaginationInput } from '../../../components/widgets';
import { CenteredInformation, LoadingIndicator } from '../../../components/utils';
import { EventsTable } from '../../../components/tables';

import { get } from '../../../libs/client';
import stores from '../../../libs/stores';

import { QuerifySearch, DecodifySearch } from '../../../utils/StringUtils';

import selects from '../../../constants/selects.json';

const PAGINATION_LIMIT = 15;
const PAGES = ['✅ Oyanlanan Etkinlikler', '⌛ Onay Bekleyen Etkinlikler', '🕒 Geçmiş Etkinlikler'];
const QUERIES = ['recent=true', 'status=pending', 'past=true'];

const Search = ({ query, onSearch }) => {
  const [defaults] = useState(DecodifySearch(query || window.location.search));
  const { register, reset, handleSubmit } = useForm({ defaultValues: defaults });
  useEffect(() => onSearch({ ...defaults }), []);
  return (
    <main className="main grid grid-slim align-items-end" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 0.5fr' }}>
      <CommonInput label="Etkinlik Adı" register={register('name')} placeholder="Etkinlik adıyla arayın" />
      <CommonInput label="Sanatçı" register={register('artist')} placeholder="Sanatçı adıyla arayın" />
      <CommonInput label="Mekan" register={register('venue')} placeholder="Mekan adıyla arayın" />
      <CommonInput label="Başlangıç" register={register('start')} type="date" />
      <CommonInput label="Bitiş" register={register('end')} type="date" />
      <CommonSelect label="Aktif mi?" register={register('active')} placeholder="Bir statü seçin" options={selects.boolean} defaultValue={defaults.active} />
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary btn-normal flex-grow-1"
          onClick={handleSubmit(onSearch)}
        >
          <i className="fa fa-search" />
        </button>
        {query && (
          <button
            type="button"
            className="btn btn-danger btn-normal flex-grow-1 ml-2"
            onClick={() => {
              onSearch({ ...defaults });
              reset(null);
            }}
          >
            <i className="fa fa-trash" />
          </button>
        )}
      </div>
    </main>
  );
};

const EventsGeneric = ({ initials }) => {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState('&');

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const GetData = () => get(`/events/search?page=${total}&limit=${PAGINATION_LIMIT}&${initials}&${query || ''}`)
    .then((res) => { setData(res.data); setPages(res.total); })
    .catch(console.log);

  useEffect(() => { GetData(); }, [query, total]);

  if (data) {
    if (data.length > 0) {
      return (
        <>
          <Search
            {...{ query }}
            onSearch={(q) => { setQuery(QuerifySearch(q)); setTotal(0); }}
          />
          <EventsTable
            {...{ data, pages }}
            index={(PAGINATION_LIMIT * total)}
            pager={(
              <p className="d-flex align-items-center mr-2">
                sayfa
                <CommonSelect
                  className="mx-2"
                  value={total + 1}
                  placeholder={1}
                  onChange={({ target }) => setTotal(target.value)}
                  options={Array.from(Array(Math.floor(Math.abs(pages - 1) / PAGINATION_LIMIT)))
                    .map((_, i) => ({ name: i + 2, value: i + 1 }))}
                />
                {`/ ${Math.floor(Math.abs(pages - 1) / PAGINATION_LIMIT) + 1}`}
              </p>
            )}
          />
        </>
      );
    }
    return (
      <CenteredInformation
        title=" Hiç etkinlik yok"
        subtitle="HİBilet'ten organizatörlerin etkinlik eklemesi gerekiyor."
        fixed
      />
    );
  }
  return (<LoadingIndicator />);
};

const Events = () => {
  const [page, setPage] = useState(Number(new URLSearchParams(window.location.search).get('step')) || 0);

  useEffect(() => {
    stores.setAppbar({ left: (<h2 className="m-0">{`${stores.getAccount()?.profile?.name} mekan paneli`}</h2>) });
    stores.setBreadCrumbs([]);
  }, []);

  return (
    <main className="grid">
      <PaginationInput
        {...{ page }}
        className="mr-2"
        content={PAGES}
        onChange={setPage}
      />
      {QUERIES.map((initials, i) => (page === i) && (
        <EventsGeneric
          {...{ initials, Search }}
        />
      ))}
    </main>
  );
};

export default Events;
