import remx from 'remx';

const state = remx.state({
  tab: '/',
  appbar: null,
  account: null,
  breadCrumbs: [],
  showSidebar: false,
  notifications: [],
});

export default {
  ...remx.getters({
    getTab() { return state.tab; },
    getAppbar() { return state.appbar; },
    getAccount() { return state.account; },
    getBreadCrumbs() { return state.breadCrumbs; },
    getShowSidebar() { return state.showSidebar; },
    getNotifications() { return state.notifications; },
  }),

  ...remx.setters({
    setTab(tab) { state.tab = tab; },
    setAppbar(appbar) { state.appbar = appbar; },
    setAccount(account) { state.account = account; },
    setBreadCrumbs(breadCrumbs) { state.breadCrumbs = breadCrumbs; },
    setShowSidebar(showSidebar) { state.showSidebar = showSidebar; },
    setNotifications(notifications) { state.notifications = notifications; },
  }),
};
