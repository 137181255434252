const GalleryInput = ({ data, title }) => (
  <div>
    <p className="font-weight-bold mb-2">{title}</p>
    <div className="grid grid-6 grid-3-m">
      {data.map((i) => (
        <div key={i}>
          <div className="card">
            <img src={i} className="card-img-top" style={{ objectFit: 'cover', height: '10rem' }} alt="..." />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default GalleryInput;
