import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { CommonInput } from '../widgets';

import { get } from '../../libs/client';

import strings from '../../localization';

const Account = ({ register }) => {
  const [account, setAccount] = useState(null);

  const GetAccount = () => get('/accounts/me')
    .then(({ data }) => setAccount(data))
    .catch(console.log);

  useEffect(() => GetAccount(), []);

  return (
    <form>
      <div className="d-flex align-items-end">
        <h4 className="flex-grow-1">Hesap Bilgileri</h4>
      </div>
      <section className="grid grid-4">
        <CommonInput
          label="Kullanıcı Adı"
          placeholder="eg: Alp Giray"
          register={register('account.firstName', { required: true })}
          defaultValue={account?.firstName}
        />
        <CommonInput
          label="Kullanıcı Soyadı"
          placeholder="eg: Savrum"
          register={register('account.lastName', { required: true })}
          defaultValue={account?.lastName}
        />
        <CommonInput
          label="Kullanıcı E-Posta"
          placeholder="eg: alp@savrum.com"
          register={register('account.email', { required: true })}
          defaultValue={account?.email}
        />
        <CommonInput
          label="Kullanıcı Telefon"
          placeholder="eg: +90 555 5555"
          register={register('account.phone', { required: true })}
          defaultValue={account?.phone}
        />
      </section>
      <section className="grid grid-3">
        <CommonInput
          label="Türü"
          placeholder="eg: Admin"
          defaultValue={strings[`entity-${account?.type}`]}
          disabled
        />
        <CommonInput
          label="Referans Kodu"
          placeholder="HITR-000-000"
          defaultValue={account?.reference_code}
          disabled
        />
        <CommonInput
          label="Oluşuturulma Tarihi"
          defaultValue={dayjs.utc(account?.createdAt).format('DD MMMM YYYY, HH:mm')}
          disabled
        />
      </section>
    </form>
  );
};

export default Account;
