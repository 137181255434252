const LoadingIndicator = ({
  color = 'primary', width = 3, height = 3, show = true,
}) => (
  <div className="position-fixed center" style={{ display: show ? 'block' : 'none' }}>
    <span
      className={`spinner-border text-${color}`}
      style={{ width: `${width}rem`, height: `${height}rem` }}
      role="status"
    />
  </div>
);

export default LoadingIndicator;
