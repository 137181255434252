const setToken = (token) => {
  localStorage.setItem('token', token); window.location.replace('/');
  localStorage.removeItem('isSubscribed');
};
const getToken = () => localStorage.getItem('token');

const setLang = (lang) => { localStorage.setItem('lang', lang); window.location.reload(); };
const getLang = () => localStorage.getItem('lang');

export {
  setToken, getToken, setLang, getLang,
};
