import { Fragment } from 'react';
import { useLocation, Link } from 'wouter';
import { observer } from 'remx';

import { setToken } from '../../libs/storage';
import stores from '../../libs/stores';

import strings from '../../localization';
import Logo from '../../assets/hibilet-organizer.svg';

const sections = [
  {
    name: 'sidebar-title-admin',
    links: [
      { icon: 'calendar', name: 'sidebar-link-events', to: '/events?recent=true&active=true' },
    ],
  },
  {
    name: 'sidebar-title-account',
    links: [
      { icon: 'building', name: 'sidebar-link-profile', to: '/profile' },
      { icon: 'th-large', name: 'sidebar-link-seatings', to: '/seatings' },
      {
        icon: 'sign-out',
        name: 'sidebar-link-logout',
        to: () => {
          if (window.confirm('Çıkış yapmak istediğinize emin misiniz?')) {
            setToken(''); window.location.reload();
          }
        },
      },
    ],
  },
];

const match = (to, loc) => to.toString().includes(loc.split('/')[1].toString());

const Sidebar = () => {
  const [location] = useLocation();
  return (
    <div className={`sidebar ${stores.getShowSidebar() ? 'sidebar-show' : ''}`}>
      <section>
        {(window.location.host.includes('dev-')) && (
        <p
          className="position-absolute"
          style={{
            right: '0.5rem',
            top: '2.5rem',
            color: 'black',
            background: 'white',
            padding: '0.25rem',
            borderRadius: '0.5rem',
            border: '1px solid black',
          }}
        >
          DEV
        </p>
        )}
        <Link to="/" onClick={() => stores.setTab('/')}>
          <img className="pointer" alt="HIBilet" src={Logo} width="100%" />
        </Link>
        <hr className="my-2" />
      </section>
      <section className="menu">
        {sections.map((section) => (
          <Fragment key={section.name}>
            <h5>{strings[section.name]}</h5>
            {section.links.map(({ icon, name, to }) => (
              <Link
                key={name}
                className={`mb-2 btn ${(match(to, location)) ? 'btn-primary' : ''} w-100 text-left`}
                to={typeof (to) === 'string' ? to : '/'}
                onClick={() => (typeof (to) === 'string' ? stores.setTab(to) : to())}
              >
                <i className={`fa fa-${icon} mr-2`} />
                {strings[name]}
              </Link>
            ))}
          </Fragment>
        ))}
      </section>
      {/* <section className="d-flex flex-column align-items-center">
        <img
          alt="guide"
          className="w-50"
          style={{ filter: 'hue-rotate(335deg)' }}
          src="https://demos.creative-tim.com/argon-dashboard/assets/img/illustrations/icon-documentation.svg"
        />
        <p><b>{strings['sidebar-title-help']}</b></p>
        <p className="mb-2">{strings['sidebar-link-help']}</p>
        <a to="/" className="btn btn-secondary w-100">{strings['sidebar-link-guide']}</a>
      </section> */}
    </div>
  );
};

export default observer(Sidebar);
