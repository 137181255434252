/* eslint-disable no-underscore-dangle */
import strings from '../../localization';

const CommonSelect = ({
  register, placeholder, options = [], domain, defaultValue, defaultOption,
  prepend, append, small, label, className, style, onChange, size, disabled,
  inputClassName, tooltip, placeholderValue,
}) => (
  <div className={`form-group mb-0 ${className || ''}`} style={style}>
    {label && <p className="font-weight-bold mb-2">{label}</p>}
    {tooltip && (
      <span className="info pointer ml-2">
        <i className="fa fa-question-circle" />
        <span className="info-text">
          {tooltip}
        </span>
      </span>
    )}
    <div className="input-group">
      {prepend && (
      <div className="input-group-prepend">
        <span className="input-group-text">{prepend}</span>
      </div>
      )}
      {disabled ? (
        <input
          className={`form-control form-control-${size}`}
          defaultValue={options.find(({ value }) => value === defaultValue)?.name}
          {...{ placeholder }}
          disabled
        />
      ) : (
        <select
          className={`form-control form-control-${size} ${inputClassName}`}
          defaultValue={(domain ? (defaultValue?._id || defaultValue) : defaultValue) || ''}
          disabled={disabled}
          onChange={onChange}
          {...register}
        >
          <option value={placeholderValue || ''}>{placeholder}</option>
          {defaultOption?.value && (
          <option
            selected
            value={defaultOption.value}
          >
            {defaultOption.name}
          </option>
          )}
          {options.filter((o) => o.value !== defaultOption?.value).map(({ value, name }) => (
            <option
              key={value}
              {...{ value }}
            >
              {domain ? strings[`${domain}-${name}`] : name}
            </option>
          ))}
        </select>
      )}
      {append && (
        <div className="input-group-append">
          <span className="input-group-text">{append}</span>
        </div>
      )}
    </div>
    {small && <small className="form-text text-muted">{small}</small>}
  </div>
);

export default CommonSelect;
