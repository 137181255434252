/* eslint-disable no-underscore-dangle */

import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { LoadingIndicator } from '../../../components/utils';
import { AccountForm } from '../../../components/forms';

import { get, put } from '../../../libs/client';

import Profile from './Profile';
import Address from './Address';

import stores from '../../../libs/stores';

const Venue = () => {
  const [data, setEntity] = useState(null);
  const [categories, setCategories] = useState(null);
  const { register, watch, handleSubmit } = useForm();

  const GetVenue = () => get('/venues/me')
    .then((res) => (res.data ? setEntity(res.data) : setEntity({})))
    .catch((err) => console.log(err));

  const GetVenueCategories = () => get('/venues/categories')
    .then((res) => setCategories(res.data))
    .catch((err) => console.log(err));

  const UpdateVenue = (form, soft) => {
    put('/accounts/me', form.account)
      .then(() => put('/venues/me', form)
        .then(() => {
          alert('Mekan güncellendi');
          if (soft) { GetVenue(); } else { window.location.reload(); }
        })
        .catch(() => alert('Mekan güncellenirken bir hata oldu')))
      .catch(() => alert('Hesap güncellenirken bir hata oldu'));
  };

  useEffect(() => { GetVenue(); GetVenueCategories(); }, []);
  useEffect(() => {
    stores.setAppbar({
      back: '/events',
      left: (<h2 className="m-0">Mekan Bilgileri</h2>),
      right: (
        <button type="button" className="btn btn-primary" onClick={handleSubmit(UpdateVenue)}>
          <i className="fa fa-save mr-2" />
          {data ? 'KAYDET' : 'MEKAN OLUŞTUR'}
        </button>
      ),
    });
    stores.setBreadCrumbs([
      { to: '/profile', title: 'Mekanlar' },
      { to: '/profile', title: data?.name },
      { to: '/profile', title: 'Temel Bilgiler' },
    ]);
  }, [data]);

  return (
    (data && categories)
      ? (
        <main className="grid">
          <AccountForm {...{ register }} />
          <Profile
            {...{
              data, watch, register, categories,
            }}
            onActiveChange={() => UpdateVenue({ active: !data.active }, true)}
          />
          <Address {...{ data, watch, register }} />
        </main>
      )
      : <LoadingIndicator />
  );
};

export default Venue;
