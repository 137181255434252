/* eslint-disable no-underscore-dangle */

import { useEffect, useState } from 'react';
import { LoadingIndicator } from '../../../../components/utils';
import {
  ArtistInput, CommonInput, GalleryInput, ImageInput,
} from '../../../../components/widgets';
import { get } from '../../../../libs/client';

import strings from '../../../../localization';

const maxLength = 140;

const EventBasicInfo = ({ show, params, onEventChange }) => {
  const [event, setEvent] = useState(null);

  console.log(params);

  const GetEvent = () => get(`/events/${params.id}`)
    .then(({ data }) => setEvent(data))
    .catch((err) => console.log(err));

  useEffect(() => { GetEvent(); }, []);
  useEffect(() => onEventChange(event), [event]);

  return (
    event ? (
      <section className="grid" style={{ display: show ? 'grid' : 'none' }}>
        <form>
          <h4>Temel Bilgiler</h4>
          <section className="grid grid-2">
            <CommonInput
              disabled
              label="Etkinlik Adı"
              placeholder="eg: Sunset Events"
              defaultValue={event?.name}
            />
            <CommonInput
              label="Etkinlik Kategorisi"
              defaultValue={strings[`category-${event?.category?.handle}`]}
              disabled
            />
          </section>
        </form>
        <form>
          <h4>Etkinlik Açıklaması</h4>
          <section className="grid grid-2">
            <CommonInput
              disabled
              label="Türkçe Açıklama"
              defaultValue={event?.desc?.tr}
              length={event?.desc?.tr?.length}
              limit={maxLength}
              multiline
            />
            <CommonInput
              disabled
              label="İngilizce Açıklama"
              defaultValue={event?.desc?.en}
              length={event?.desc?.en?.length}
              limit={maxLength}
              multiline
            />
          </section>
        </form>
        <form>
          <h4>Etkinlik Görselleri</h4>
          <section className="grid grid-2">
            <ImageInput
              disabled
              defaultValue={event?.image}
              resolution="1024x1024"
              ratio="1:1"
              placeholder="Uygulamada, ana sayfada gösterilecek olan görsel"
              label="Ana Etkinlik Görseli"
            />
            <ImageInput
              disabled
              defaultValue={event?.qr}
              resolution="512x512"
              ratio="1:1"
              placeholder="Uygulamada, biletlerin üzerinde gösterilecek olan görsel"
              label="QR Kod Görseli"
            />
          </section>
          {event?.gallery?.length > 0 && <GalleryInput title="Etkinlik Galerisi" data={event?.gallery} />}
        </form>
        <form>
          <h4>Etkinlik Sanatçıları</h4>
          <ArtistInput data={event?.artists} />
        </form>
        <form>
          <h4 className="d-flex align-items-end">
            <span className="flex-grow-1">Etkinlik Bildirileri</span>
            <span className="h6 ml-2">(Sadece etkinlik hakkında acil bilgiler için kullanılır)</span>
          </h4>
          <section className="grid grid-2">
            <CommonInput
              disabled
              placeholder="Yok"
              label="Türkçe Bildiriler"
              defaultValue={event?.notes?.tr}
              length={event?.notes?.en?.length}
              limit={maxLength}
              multiline
            />
            <CommonInput
              disabled
              placeholder="Yok"
              label="İngilizce Bildirileri"
              defaultValue={event?.notes?.en}
              length={event?.notes?.en?.length}
              limit={maxLength}
              multiline
            />
          </section>
        </form>
      </section>
    ) : <LoadingIndicator />
  );
};

export default EventBasicInfo;
