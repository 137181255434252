/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { get, put } from '../../libs/client';
import { LoadingIndicator } from '../utils';

const EventSaleSessionsTable = ({ id, event, index }) => {
  const [sale, setSale] = useState(null);
  const [sessions, setSessions] = useState(null);

  const GetSessions = () => get(`/events/${event?._id}/sales/${sale?._id}/sessions`)
    .then(({ data }) => setSessions(data))
    .catch((err) => console.log(err));

  const GetSale = () => get(`/events/${event?._id}/sales/${id}`)
    .then(({ data }) => setSale(data))
    .catch((err) => console.log(err));

  const UpdateSale = () => window.confirm(sale?.venue_confirmation === 'confirmed'
    ? 'Etkinlik satış onayını geri çekmek istediğinize emin misiniz?'
    : 'Etkinlik satışını onaylamak istediğinize emin misiniz?')
    && put(`/events/${event?._id}/sales/${sale?._id}`, { venue_confirmation: sale?.venue_confirmation === 'confirmed' ? 'pending' : 'confirmed' })
      .then(({ data }) => setSale(data))
      .catch((err) => console.log(err));

  useEffect(() => GetSale(), []);
  useEffect(() => sale && GetSessions(), [sale]);

  return (
    <main className="main p-0 table-responsive position-relative">
      <section className="p-4 d-flex align-items-center">
        <h4 className="fl-1">
          Satış
          {' '}
          {index + 1}
        </h4>
        {sessions && (
          <button
            type="button"
            className={`btn btn-${sale?.venue_confirmation === 'confirmed' ? 'danger' : 'success'}`}
            onClick={() => UpdateSale()}
          >
            <i className={`fa fa-${sale?.venue_confirmation === 'confirmed' ? 'times' : 'check'} mr-2`} />
            {sale?.venue_confirmation === 'confirmed' ? 'ONAYI GERİ ÇEK' : 'ETKİNLİK SATIŞINI ONAYLA'}
          </button>
        )}
      </section>
      {sessions ? (
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col" className="text-center">#</th>
              <th scope="col">Başlangıç Tarihi</th>
              <th scope="col">Bitiş Tarihi</th>
              <th scope="col">Oturma Planı</th>
              <th scope="col">Toplam Bilet</th>
              <th scope="col">Satılan Bilet</th>
              <th scope="col">Kalan Bilet</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map((session, i) => (
              <tr key={session._id} className="pointer">
                <td className="text-center">{`# ${i + 1}`}</td>
                <td>{dayjs.utc(session?.start).format('DD MMM YYYY HH:mm')}</td>
                <td>{dayjs.utc(session?.end).format('DD MMM YYYY HH:mm')}</td>
                <td>{session?.seating_plan?.name || 'Ayakta Etkinlik'}</td>
                <td>{session?.tickets_stock}</td>
                <td>{session?.tickets_sold || 'Henüz satış yapılmamış'}</td>
                <td>{((session?.tickets_stock) - (session?.tickets_sold)) || 'Henüz satış yapılmamış'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : <LoadingIndicator />}
    </main>
  );
};

export default EventSaleSessionsTable;
