const PaginationInput = ({
  content, page, offset = 0, className, onChange,
}) => (
  <div className={`btn-group btn-group ${className}`} data-toggle="buttons">
    {content.map((c, i) => (
      <button
        key={c}
        type="button"
        className={`btn btn-outline-primary one-line ${(page) === (i + offset) && 'active'}`}
        onClick={() => onChange(i + offset)}
      >
        {c}
      </button>
    ))}
  </div>
);

export default PaginationInput;
