import { useRef } from 'react';

const SEATING_TARGET = 'https://seating.hibilet.com';
// const SEATING_TARGET = 'http://localhost:5173';
// const SEATING_TARGET = 'http://10.241.158.51:1234';

const SeatingEngine = ({ plan }) => {
  const engine = useRef(null);
  const Message = (msg) => {
    if (msg.method) {
      engine?.current?.contentWindow?.postMessage(msg, '*');
    } else { console.log('No method given.'); }
  };

  return (
    <div>
      <section className="grid grid-slim">
        <main className="d-flex flex-column align-items-center justify-content-center bg-light">
          <div className="d-flex flex-row w-100 engine">
            <iframe
              ref={engine}
              className="seating-plan w-100"
              title="Oturma Planı"
              src={SEATING_TARGET}
              onLoad={() => {
                Message({ method: 'POST/ UserType', data: 'organizer' }, '*');
                Message({ method: 'POST/ Category', data: -1 }, '*');
                Message({ method: 'POST/ Layout', data: plan }, '*');
                Message({ method: 'POST/ PaintSeats' }, '*');
              }}
            />
          </div>
        </main>
      </section>
    </div>
  );
};

export default SeatingEngine;
