import React from 'react';
import ReactDOM from 'react-dom';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';

import { getToken } from './libs/storage';
import { Auth, Panel } from './screens';

import './index.css';
import { DecodifySearch } from './utils/StringUtils';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('tr');

const Ordos = () => {
  ReactDOM.render((() => {
    if (DecodifySearch(window.location.search).token) { return <Auth />; }
    if (getToken()) { return <Panel />; }
    return <Auth />;
  })(), document.getElementById('app'));
}; Ordos();
