/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';

import { PaginationInput } from '../../../../components/widgets';

import BasicInfo from './BasicInfo';
import Sales from './Sales';

import stores from '../../../../libs/stores';
import { DecodifySearch } from '../../../../utils/StringUtils';

const PAGES = ['Etkinlik Bilgileri', 'Etkinlik Seansları'];

const Event = ({ params }) => {
  const [step, setStep] = useState(Number(DecodifySearch(window.location.search)?.step) || 0);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    stores.setAppbar({
      back: '/events?step=1',
      left: (<h2 className="m-0">{event ? `${event?.name} detayları` : 'Yükleniyor'}</h2>),
      right: step === 0 && null,
    });
    stores.setBreadCrumbs([
      { to: '/events', title: 'Etkinlikler' },
      { to: `/events/${event?._id || 'new'}`, title: event?.name || 'Yükleniyor' },
      { to: `/events/${event?._id}`, title: PAGES[step] },
    ]);
  }, [event, step]);

  return (
    <main className="grid">
      <PaginationInput
        className="mr-2"
        content={PAGES}
        page={step}
        onChange={setStep}
      />
      <BasicInfo
        show={step === 0}
        params={params}
        onEventChange={setEvent}
      />
      {step === 1 && (
        <Sales
          event={event}
        />
      )}
    </main>
  );
};

export default Event;
