/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';

import { CenteredInformation, LoadingIndicator, SeatingEngine } from '../../../components/utils';

import { get } from '../../../libs/client';
import stores from '../../../libs/stores';

const Seating = ({ data }) => (
  <form>
    <details>
      <summary className="d-flex align-items-center">
        <h4 className="flex-grow-1 m-0 mb">{data.name}</h4>
      </summary>
      <SeatingEngine plan={data} className="mt-2" />
    </details>
  </form>
);

const VenueSeating = () => {
  const [seatings, setSeatings] = useState(null);

  const GetSeatings = () => get('/venues/me/seating')
    .then(({ data }) => setSeatings(data))
    .catch((err) => console.log(err));

  useEffect(() => {
    stores.setAppbar({ left: (<h2 className="m-0">Oturma Planları</h2>) });
    GetSeatings();
  }, []);

  if (seatings) {
    if (seatings.length > 0) {
      return (<main className="grid">{(seatings).map((seating) => (<Seating key={seating._id} data={seating} />))}</main>);
    } return (<CenteredInformation title="Oturma planı yok" subtitle="Mekana ait bir oturma planı eklenmemiş" fixed />);
  } return <LoadingIndicator />;
};

export default VenueSeating;
