import { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'wouter';
import { observer } from 'remx';

import dayjs from 'dayjs';
import { Sidebar, Appbar } from '../../components/navigation';
import { LoadingIndicator } from '../../components/utils';

import { setToken } from '../../libs/storage';
import { get } from '../../libs/client';
import stores from '../../libs/stores';

import Events from './Events';
import Event from './Events/Event';

import Profile from './Profile';
import Seatings from './Seatings';

const routes = [
  { path: '/events', component: Events },
  { path: '/events/:id', component: Event },
  { path: '/profile', component: Profile },
  { path: '/seatings', component: Seatings },
];

const SubscribeForm = ({ onSubscribe, onCancel }) => (
  <div className="modal fade" style={{ display: 'block', background: '#000000AA', opacity: 1 }}>
    <div
      className="modal-dialog"
      style={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">ES Events Kullanım Koşulları</h5>
        </div>
        <div className="modal-body">
          Mekana ilişkin; mekan ismi, mekan telefonu, mekan e-postası,
          mekan adresi, mekan iletişim bilgileri ve ES Events kayıt tarihi bilgileri
          uygulamada paylaşılacak olup,
          bu bilgilerin uygulamada yayınlanmasına mekan olarak açıkça onay vermekteyim.
        </div>
        <div className="modal-footer">
          <button onClick={onCancel} type="button" className="btn btn-danger" data-dismiss="modal">Reddet</button>
          <button onClick={onSubscribe} type="button" className="btn btn-success">Kabul et</button>
        </div>
      </div>
    </div>
  </div>
);

const Panel = () => {
  const [showSubscribed, setShowSubscribed] = useState(false);

  const GetSubcribed = () => {
    setShowSubscribed(!localStorage.getItem('isSubscribed'));
  };

  const GetAccount = () => get('/accounts/me')
    .then((account) => get('/venues/me')
      .then((venue) => {
        stores.setAccount({ ...account.data, profile: venue.data });
        GetSubcribed();
      })
      .catch(() => setToken('')))
    .catch(() => setToken(''));

  useEffect(() => {
    GetAccount();
  },
  []);

  return (stores.getAccount() ? (
    <div className="app-wrapper">
      {showSubscribed && (
        <SubscribeForm
          onSubscribe={() => {
            localStorage.setItem('isSubscribed', dayjs().toDate());
            setShowSubscribed(false);
          }}
          onCancel={() => {
            alert('Kabul etmeden devam edemezsiniz');
          }}
        />
      )}
      {!stores.getAccount().new && <Sidebar />}
      <div className="grid app-content">
        <Appbar />
        <Switch>
          {routes.map((r) => <Route {...r} />)}
          <Redirect to="/events?recent=true&active=true" />
        </Switch>
      </div>
    </div>
  ) : <LoadingIndicator />);
};

export default observer(Panel);
