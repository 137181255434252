const ArtistInput = ({ data }) => (
  <div className="grid">
    <div className="grid grid-6 grid-2-m">
      {data?.map((i) => (
        <div key={i.name}>
          <div className="card">
            <img
              onError={(e) => { e.target.src = 'https://fakeimg.pl/512x512/?text=Hata'; }}
              src={i.photo}
              className="card-img-top"
              style={{ objectFit: 'cover', height: '10rem' }}
              alt="..."
            />
            <p className="p-2 mb-0">{i.name}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default ArtistInput;
