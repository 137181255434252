import CommonInput from './CommonInput';
// import strings from '../../localization';

const ImageInput = ({
  register = {}, label, placeholder, resolution = '512x512', ratio = '1:1', watch, defaultValue, disabled,
}) => (
  <div>
    <div className="d-flex justify-content-center">
      <img src={(watch || defaultValue) || `https://fakeimg.pl/${resolution}?text=${ratio} Oran`} alt="unknown" style={{ height: 200, objectFit: 'contain' }} />
    </div>
    <CommonInput
      label={label}
      labelClassName="w-100 mt-2"
      register={register}
      placeholder={placeholder}
      defaultValue={defaultValue}
      disabled={disabled}
      // append={(
      //   <button
      //     type="button"
      //     className="btn btn-link btn-sm p-0"
      //     onClick={() => alert('Upload to IPFS')}
      //   >
      //     <i className="fa fa-upload mr-2" />
      //     {strings['action-upload']}
      //   </button>
      //   )}
    />
  </div>
);

export default ImageInput;
