/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';

import { EventSalesSessions } from '../../../../components/tables';

import stores from '../../../../libs/stores';

const EventSales = ({ event }) => {
  useEffect(() => {
    stores.setAppbar({
      back: '/events?step=1',
      left: (<h2 className="m-0">{`${event?.name} detayları`}</h2>),
    });
  }, []);

  return (
    <section className="grid">
      {console.log(event?.sales)}
      {event?.sales?.map((sale, i) => (
        <EventSalesSessions
          index={i}
          event={event}
          id={sale?._id}
        />
      ))}
    </section>
  );
};

export default EventSales;
